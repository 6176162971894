
import { defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import useMutationCommon from '@/hooks/useMutationCommon'
import useOpenInfo from '@/hooks/openInfo'
import stepPercentage from '@/utils/number'
import { openFileView, setApptitle } from '@/utils/native-app'

export default defineComponent({
  setup () {
    const router = useRouter()
    const { openType, step, title, saveCommonOfVuex } = useOpenInfo()
    const handleNext = () => {
      const percentage = stepPercentage(step)
      const common = {
        openType: openType.value,
        openStatus: 0,
        btnText: '继续开户',
        remark: `已完成${percentage}%，请继续完善资料`,
        step
      }

      useMutationCommon(common).then(() => {
        saveCommonOfVuex({ info: common })
        router.push({ name: 'pi-ar' })
      })
    }

    onMounted(() => {
      setApptitle(title)
    })
    const showPdf = (index: number) => {
      let url = `${process.env.VUE_APP_H5_STATIC_PAGE_URL + '/static_pages/pdf/'}`
      let title
      if (index === 0) {
        url = `${url}SecuritiesaAndFuturesOrdinance.pdf`
        title = '证券及期货条例'
      } else {
        title = '操作守则'
        url = `${url}pg.pdf`
      }
      const params = {
        fileType: 'pdf',
        fileUrl: url,
        title
      }
      openFileView(params)
      // window.open(url);
    }
    return { handleNext, showPdf }
  }
})
